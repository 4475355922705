.container {
  height: 100%;
  width: 100%;
  padding: 1.2em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.inheadercontainer {
  flex: 1;
  max-width: 57rem;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-left: 50px;
  padding: auto;
  margin-top: 50px;
}

.gridItem {
  justify-content: "center";
  display: flex;
  justify-items: center;
  flex-direction: column;
}

:root {
  --pale-grey: #f5f6fa;
  --light-grey: #f7f7f7;
  --greyish: #d1d1d1;
  --brown-grey: #959595;
  --grey: #707070;
  --greyish-brown: #535353;
  --deep-sky-blue: #0583f2;
  --white: #ffffff;
  --very-light-pink: #fff2f2;
  --deep-red: #aa0000;
  --highlight-yellow: rgb(255, 246, 226);
  --standard-border-radius: 2px;
}

#root {
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  font-family: Roboto;
  font-weight: 400;
  -webkit-overflow-scrolling: touch;
}

html,
body,
#root {
  height: 100%;
}

button,
textarea,
input {
  outline: 0;
}
button {
  cursor: pointer;
  border: none;
}
button:active {
  outline: none;
  border: none;
}
button:focus {
  outline: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

pre {
  background-color: rgb(48, 48, 48);
  color: white;
}

@media only screen and (max-width: 414px) {
  .hidewhensmall {
    display: none;
  }
}

@media only screen and (min-width: 415px) {
  .hidewhenmedium {
    display: none;
  }
}

.pagetitlebox {
  margin: 0.5em 0;
  display: flex;
  align-items: center;
}

.marginleft1{
  margin-left: 1em;
}
.marginright03{
  margin-right: 0.3em;
}

.pagetitlebox1 {
  margin: 0.5em 0;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  height: 80px;
}